<template>
  <v-form @submit.prevent="submit" ref="form" class="forgot-password-form">
    <div class="forgot-password-form__content">
      <div class="forgot-password-form__fields">
        <v-text-field @input="handleEmailInput" :value="email" label="Email" />
      </div>
      <div class="forgot-password-form__footer">
        <v-btn type="submit" block>
          Send
        </v-btn>
      </div>
    </div>
  </v-form>
</template>

<script>
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';

export default {
  name: 'ForgotPasswordForm',
  mixins: [syncForm, validateForm],
  props: {
    email: String,
  },
  methods: {
    handleEmailInput(value) {
      const field = 'email';
      this.$emit(`update:${field}`, value);
      this.handleFieldInput(field, value);
    },
    submitForm() {
      const valid = this.validateForm();
      if (valid) {
        this.$emit('submit', {
          login: this.login,
          password: this.password,
        });
      }
    },
    validate() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
      throw new Error("The form wasn't mounted");
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-form__footer {
  margin-top: 0.25rem;
}
</style>
