<template>
  <div class="forgot-password-view">
    <v-container fill-height>
      <v-layout justify-center fill-height class="forgot-password-view__layout">
        <div class="forgot-password-view__form-wrapper">
          <div class="forgot-password-view__form-header">
            <div class="forgot-password-view__form-header-logo-wrapper">
              <Logo large />
            </div>
            <div class="forgot-password-view__form-header-title-wrapper">
              <FormTitle transform="capitalize">Password reset</FormTitle>
            </div>
          </div>
          <div class="forgot-password-view__form-body">
            <div v-if="message" class="forgot-password-view__form-text-wrapper">
              <p v-html="message" class="forgot-password-view__form-text mb-0"></p>
            </div>
            <transition name="fade">
              <ForgotPasswordForm
                v-if="!forgotPasswordFormState.sent"
                @submit="submitForgotPasswordForm"
                :email.sync="forgotPasswordForm.email"
                ref="forgotPasswordForm"
              />
              <div v-else class="forgot-password-view__form-resend-wrapper">
                <p class="forgot-password-view__form-resend-text">
                  You can keep this page open while you check your email. If you don't receive the
                  instructions within a minute or two try resending the instructions.
                </p>
                <v-btn @click="reSubmitForgotPasswordForm" block>
                  Re-send
                </v-btn>
              </div>
            </transition>
          </div>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm';
import FormTitle from '@/components/typography/FormTitle';
import Logo from '@/components/Logo';

const MESSAGES = {
  instructions:
    'To reset you password enter your email address or phone number below and follow the instructions that we will send to you.',
  sent: `<span>E-mail was sent to</span>
         <br>
         <a href="mailto:{email}">{email}</a>
         <br>
         <span>We've sent the instructions to your email. Go check it</span>`,
  reSent: `<span>E-mail was re-sent to</span>
           <br>
           <a href="mailto:{email}">{email}</a>
           <br>
           <span>We've sent the instructions to your email. Make sure you check your spam filters.</span>`,
};

const DIALOG_TITLE = 'Reset password';

export default {
  name: 'ForgotPassword',
  components: {
    ForgotPasswordForm,
    FormTitle,
    Logo,
  },
  data() {
    return {
      forgotPasswordForm: {
        email: '',
      },
      forgotPasswordFormState: {
        sent: false,
      },
    };
  },
  computed: {
    message() {
      if (this.forgotPasswordFormState.sent) {
        return MESSAGES.sent.replace(/{email}/g, this.forgotPasswordForm.email);
      }
      return MESSAGES.instructions;
    },
  },
  methods: {
    ...mapActions('user', ['restorePasswordByEmail']),
    ...mapActions('captcha', ['executeAction']),
    async submitForgotPasswordForm() {
      try {
        await this.executeAction('restorePassword');
        const success = await this.restorePasswordByEmail(this.forgotPasswordForm.email);
        if (success) {
          this.forgotPasswordFormState.sent = true;
        }
        return success;
      } catch (error) {
        this.$dialog.error({ title: DIALOG_TITLE, error });
        return error;
      }
    },
    async reSubmitForgotPasswordForm() {
      const success = await this.submitForgotPasswordForm();
      if (success) {
        this.$dialog.info({
          title: DIALOG_TITLE,
          description: MESSAGES.reSent.replace(/{email}/g, this.forgotPasswordForm.email),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.forgot-password-view {
  height: 100%;
}
.forgot-password-view__layout {
  padding: 120px 0px;
}
.forgot-password-view__form-wrapper {
  width: 360px;
}
.forgot-password-view__form-header-logo {
  height: 67px;
}
.forgot-password-view__form-header-logo-wrapper {
  margin-bottom: 33px;
}
.forgot-password-view__form-text {
  margin-bottom: 0px;
  color: $text-semi-dark;
  font-size: 1rem;
  font-weight: 300;
  a {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
  }
}
.forgot-password-view__form-body {
  margin-top: 21px;
}
.forgot-password-view__form-resend-wrapper {
  margin-top: 16px;
}
.forgot-password-view__form-text-wrapper {
  margin-bottom: 2rem;
}
.forgot-password-view__form-resend-text {
  font-size: 0.6875rem;
  font-weight: 300;
  line-height: 1.36;
  margin-bottom: 1.25rem;
}
</style>
